<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{
  class?: HTMLAttributes["class"];
}>();
</script>

<template>
  <h5 :class="cn('font-medium leading-none text-on-surface', props.class)">
    <slot />
  </h5>
</template>
