<script setup lang="ts">
import { cva } from "class-variance-authority";
import type { HTMLAttributes } from "vue";

const props = withDefaults(
  defineProps<{
    variant?: "info" | "success" | "warning" | "error";
    class?: HTMLAttributes["class"];
  }>(),
  {
    variant: "info",
    class: "",
  },
);

const containerClass = computed(() => {
  return cva("w-full flex p-4 border rounded-xl space-x-3", {
    variants: {
      variant: {
        info: "border-blue-700",
        success: "border-green-600",
        warning: "border-orange-400",
        error: "border-red-500",
      },
    },
  })({
    variant: props.variant,
  });
});
</script>

<template>
  <div :class="cn(containerClass, props.class)" role="alert">
    <slot />
  </div>
</template>
