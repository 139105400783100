<script setup lang="ts">
import { cva } from "class-variance-authority";

const props = defineProps<{
  variant: "success" | "warning" | "error" | "info";
}>();

const iconName = computed(() => {
  const icons = {
    success: "heroicons:check-circle-solid",
    warning: "heroicons:exclamation-triangle-20-solid",
    error: "heroicons:x-circle-20-solid",
    info: "heroicons:information-circle-20-solid",
  };

  return icons[props.variant];
});

const iconClass = computed(() => {
  return cva("size-6", {
    variants: {
      variant: {
        info: "text-blue-700",
        success: "text-green-600",
        warning: "text-orange-400",
        error: "text-red-500",
      },
    },
  })({
    variant: props.variant,
  });
});
</script>

<template>
  <Icon :name="iconName" :class="iconClass" />
</template>
s
