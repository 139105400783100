<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant: "info" | "success" | "warning" | "error";
    title?: string;
    description: string;
    dismissible?: boolean;
  }>(),
  {
    variant: "info",
    title: undefined,
    dismissible: false,
  },
);

const showAlert = ref(true);
</script>

<template>
  <UIAlertRoot
    v-if="showAlert"
    :variant="props.variant"
    :class="[props.title ? 'items-start' : 'items-center']"
  >
    <div class="shrink-0">
      <UIAlertIcon :variant="props.variant" />
    </div>
    <div class="flex min-w-0 flex-1 flex-col gap-2">
      <UIAlertTitle v-if="props.title">
        {{ props.title }}
      </UIAlertTitle>

      <UIAlertDescription>
        {{ props.description }}
      </UIAlertDescription>
    </div>
    <div v-if="props.dismissible" class="shrink-0">
      <UIButtonIcon
        icon="ic:round-close"
        :label="$t('close')"
        @click="showAlert = false"
        class="-m-1 p-0.5 text-outline"
      />
    </div>
  </UIAlertRoot>
</template>
